import React, {useState, useEffect} from 'react'  //rafce
import {FaBars} from 'react-icons/fa'
import {Nav, Navbar_Container, Nav_logo, Nav_log, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink} from "./NavbarElements"

import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import ico from '../../images/ico.png';
const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = ()=> {
        if(window.scrollY >= 80 ) {
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
     
    
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    return (
     <>
     <IconContext.Provider value = {{color: '#fff'}}>
     <Nav scrollNav = {scrollNav}>
         <Navbar_Container >
             {/* put an image */}
                <Nav_log src={ico}></Nav_log>
             
             {/* <Nav_logo to = "/" onClick={toggleHome} >TensorLogic</Nav_logo> */}
             
             <MobileIcon onClick={toggle}>
                 <FaBars/>
             </MobileIcon>
             <NavMenu>
                 <NavItem>
                     <NavLinks to='about' >About</NavLinks>
                 </NavItem>
                  <NavItem>
                     <NavLinks to='discover'>Discover</NavLinks>
                 </NavItem>
                  <NavItem>
                     <NavLinks to='services'>Services</NavLinks>
                 </NavItem>
                  <NavItem>
                     <NavLinks to="/signup">Sign Up</NavLinks>
                 </NavItem>
             </NavMenu>
             <NavBtn>
                 <NavBtnLink to='/signin'>Sign In</NavBtnLink>
             </NavBtn>
            

         </Navbar_Container>
     </Nav>
     </IconContext.Provider>
     </>
    )
};

export default Navbar

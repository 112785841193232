import React from 'react';

import { ServicesCard, ServicesH1, ServicesIcon, ServicesP, ServicesContainer, ServicesH2, ServicesWrapper } from './ServicesElement'


import Icon1 from '../../images/devices.svg';
import Icon2 from '../../images/iphone.svg';
import Icon3 from '../../images/android.svg';


const Services = () => {
    return (
        <ServicesContainer id='services'>
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Ensure data security</ServicesH2>
                    <ServicesP>We help defend againt cyber attacks through deception technology</ServicesP>


                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Secure you devices</ServicesH2>
                    <ServicesP>Secure you devices by running detailed audits and security patches

                    </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Recycle Android Devices</ServicesH2>
                    <ServicesP>Process, Erase and Evaluate multiple Android at once</ServicesP>
                </ServicesCard>


                
            </ServicesWrapper>


        </ServicesContainer>
    )
}

export default Services

import React from 'react'
import { Container, Form, FormButton, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon,Text } from './SigninEelements'
import Footer from '../Footer'

const Signin = () => {
    return (
        <>
        <Container>
            <FormWrap>
               {  <Icon to ='/'>Hope</Icon> }
                <FormContent>
                    <Form action='#'>
                        <FormH1>Sign in to your account</FormH1>
                        <FormLabel htmlFor='for'>Email</FormLabel>
                        <FormInput type='email' required/>
                          <FormLabel htmlFor='for'>Password</FormLabel>
                          <FormInput type='password' required/>
                          <FormButton type='submit'>Submit</FormButton>
                          <Text>Forgot password</Text>
                        
                    </Form>
                </FormContent>

            </FormWrap>
        </Container>
            <Footer/>
        </>
    )
}

export default Signin
import img1 from '../../images/phone.svg'
import img2 from '../../images/sync.svg'
import img3 from '../../images/savingmoney.svg'


export const homeObjOne ={
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine : 'Premium Service',
    headLine : 'Estimated price on device preformance , looks and specs',
    description: 'We programatically extract the device hardware preformance, battery health, average usage and more',
    buttonLabel: 'Get Started',
    imgStart: true,
    img : img1,
    alt:'Car',
    dark: true,
    primary: true,
    darkText: false

};
export const homeObjTwo ={
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: true,
    topLine : 'Premium Service',
    headLine : 'Estimated price on device preformance , looks and specs',
    description: 'We programatically extract the device hardware preformance, battery health, average usage and more',
    buttonLabel: 'Get Started',
    imgStart: true,
    img : img2,
    alt:'Car',
    dark: true,
    primary: true,
    darkText: false

};
export const homeObjThree ={
    id: 'signup',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine : 'Premium Service',
    headLine : 'Estimated price on device preformance , looks and specs',
    description: 'We programatically extract the device hardware preformance, battery health, average usage and more',
    buttonLabel: 'Sign Up',
    imgStart: true,
    img : img3,
    alt:'Car',
    dark: false,
    primary: true,
    darkText: false

};

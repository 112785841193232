import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './pages';
import SigninPage from './pages/signin';
import Navbar from './component/navbar';
import SignupPage from './pages/signup';



function App() {
  return (
    <Router>
     {/*  <Navbar/> */}
      <Switch>
        <Route path ='/' component={Home} exact />
          <Route path ='/signin' component={SigninPage} exact />
           <Route id= 'signupp' path ='/signup' component={SignupPage} exact />
         


      </Switch>

  </Router>
  );
};

export default App;

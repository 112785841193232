import React from 'react'

const SignupPage = () => {
    return (
        <div>
            <h1>Coming soon</h1>
        </div>
    )
}

export default SignupPage

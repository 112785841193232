import React from 'react'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLink, FooterLinkItems, FooterLinksTitle, FooterLinksWrapper, SocialLogo , SocialIcon, SocialMediaWrap, SocialMedia, SocialIconLink,WebsiteRights } from './FooterElement'
import { FaAmazon, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';

   const toggleHome = () => {
        scroll.scrollToTop();
    };
const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>  

                       
                        <FooterLinkItems>
                             <FooterLinksTitle>About Us</FooterLinksTitle>
                             <FooterLink to ="/signin">How Does It Work</FooterLink>
                              <FooterLink to ="/signin">Testimonials</FooterLink>
                               <FooterLink to ="/signin">Careers</FooterLink>
                                <FooterLink to ="/signin">Investors</FooterLink>
                                 <FooterLink to ="/signin">Terms Of Service</FooterLink>
                                 </FooterLinkItems>
                                    
                                    <FooterLinkItems>
                                         <FooterLinksTitle>Evaluation</FooterLinksTitle>
                             <FooterLink to ="/signin">Market Analysis</FooterLink>
                              <FooterLink to ="/signin">Predictions</FooterLink>
                               <FooterLink to ="/signin">Bidding</FooterLink>
                                <FooterLink to ="/signin">Leave it to us</FooterLink>
                                 <FooterLink to ="/signin">Pricing Method</FooterLink>
                                 </FooterLinkItems>
                                 
                      
                    </FooterLinksWrapper>
                          <FooterLinksWrapper>  

           
                        <FooterLinkItems>
                                         <FooterLinksTitle>Contact Us</FooterLinksTitle>
                             <FooterLink to ="/signin">Contact</FooterLink>
                              <FooterLink to ="/signin">Support</FooterLink>
                               <FooterLink to ="/signin">Goals</FooterLink>
                                <FooterLink to ="/signin">Sponsership</FooterLink>
                                 </FooterLinkItems>
              
                                    <FooterLinkItems>
                                                               <FooterLinksTitle>Social Media</FooterLinksTitle>
                             <FooterLink to ="/signin">Instagram</FooterLink>
                              <FooterLink to ="/signin">YouTube</FooterLink>
                               <FooterLink to ="/signin">Amazon</FooterLink>
                                <FooterLink to ="/signin">Twitter</FooterLink>
                                 <FooterLink to ="/signin">Linkedin</FooterLink>
                                 </FooterLinkItems>
                                 
                      
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            TensorLogic

                        </SocialLogo>
                        <WebsiteRights>TensorLogic © {new Date().getFullYear()} All rights reserved</WebsiteRights>
                        <SocialIcon>
                            <SocialIconLink href='/' target = '_blank' aria-label = 'Instagram'>
                                <FaInstagram/>

                            </SocialIconLink>
                                          <SocialIconLink href='//www.youtube.com' target = '_blank' aria-label = 'Youtube'>
                                <FaYoutube/>

                            </SocialIconLink>
                                          <SocialIconLink href='/' target = '_blank' aria-label = 'Amazon'>
                                <FaAmazon/>

                            </SocialIconLink>
                                          <SocialIconLink href='//www.twitter.com' target = '_blank' aria-label = 'Twitter'>
                                <FaTwitter/>

                            </SocialIconLink>
                                          <SocialIconLink href='/' target = '_blank' aria-label = 'Linkedin'>
                                <FaLinkedin/>

                            </SocialIconLink>

                        </SocialIcon>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
            
        </FooterContainer>
    )
}

export default Footer

import React, {useState} from 'react'
import Video from '../../video/video.mp4'
import { Button } from '../ButtonElement'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroH2, HeroBtnWrapper, Herop, ArrowForward, ArrowRight } from './HeroElements'
const HeroSection = () => {

    const [hover, setHover] = useState(false)
    const onHover=()=>{
        setHover(!hover)
    }
    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
            </HeroBg>
            <HeroContent>
                <HeroH1>SECURE YOUR DEVICES AND NETWORK </HeroH1>
                <HeroH2><br></br>
                </HeroH2>
                <Herop>COMING SOON</Herop>
                <Herop></Herop>
                <HeroBtnWrapper>
                    <Button to ='signup' onMouseEnter={onHover} onMouseLeave={onHover}
                    primary ='true'
                    dark ='true'
                    
                    >Get Started {hover ? <ArrowForward/> : <ArrowRight />}
                    
                    </Button>
                </HeroBtnWrapper>

            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection

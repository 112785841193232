import React from 'react'
import Signin from '../component/Signin'

const SigninPage = () => {
    return (
        <>
            <Signin/>
        </>
    );
}

export default SigninPage
